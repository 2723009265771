import React, {useContext, useEffect, useState} from 'react';
import "./ProfileTransactions.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import emptyImg from "../../img/transactionEmptyImg.svg";
import {useNavigate} from "react-router-dom";
import UserService from "../../services/UserService";
import Spinner from "../../components/Spinner/Spinner";

function ProfileTransactions() {
	const {store} = useContext(Context);
	const [transaction, setTransaction] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();


	useEffect(() => {
		async function fetchData() {
			try {
				const response = await UserService.fetchTransactions(store.user.id);
				console.log(response.data)
				setTransaction(response.data);
				setLoading(false);

			} catch (e) {
				console.log(e);
				setLoading(false);
			}
		}

		fetchData().then(r => r);
	}, []);

	const statusStyles = {
		pending: {
			color: '#808080',
			text: 'В процессе'
		},
		completed: {
			color: '#00FF07FF',
			text: 'Успешно'
		},
		failed: {
			color: '#FF0000FF',
			text: 'Неуспешно'
		}
	};

	const formatDate = (utcDateString) => {
		const date = new Date(utcDateString);
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		};
		return date.toLocaleString(undefined, options);
	};


	const linkToMain = () => {
		navigate("/");
		window.scrollTo(0, 0);
	}
	return (
		<div className="transactions">
			{
				loading ?
					<Spinner/>
					:
					transaction.length > 0 ?
						<div className="wrapper">
							<div className="table-wrapper">
								<table className="transaction-table">
									<thead>
									<tr>
										<th>ID Транзакции</th>
										<th>Отправлено</th>
										<th>Получено</th>
										<th>Курс</th>
										<th>Дата</th>
										<th>Статус</th>
									</tr>
									</thead>
									<tbody>
									{transaction.map((transaction, i) => (
										<tr key={i}>
											<td>{transaction.orderId}</td>
											<td>{transaction.sumIn} ₽</td>
											<td>{transaction.sumOut} $</td>
											<td>{transaction.rate}</td>
											<td>{formatDate(transaction.time)}</td>
											<td style={{color: statusStyles[transaction.status]?.color}}>
												{statusStyles[transaction.status]?.text || ""}
											</td>
										</tr>
									))}
									</tbody>
								</table>
							</div>
						</div>
						:
						<div className="__empty">
						<div className="__image">
								<img src={emptyImg} alt="img"/>
							</div>
							<h2>На данный момент у вас нет завершённых обменов или транзакций</h2>
							<button onClick={linkToMain}>Приступить</button>
						</div>
			}

		</div>
	);
}

export default observer(ProfileTransactions);