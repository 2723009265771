import React, {useContext, useState} from 'react';
import { useLocation } from 'react-router-dom';
import "./ExchangeDetails.scss";
import CustomSelect from "../CustomSelect/CustomSelect";
import PaymentService from "../../services/PaymentService";
import {Context} from "../../index";

function ExchangeDetails() {
	const {store} = useContext(Context);
	const [wallet, setWallet] = useState('');
	const [network, setNetwork] = useState('');
	const [memo, setMemo] = useState('');
	const [note, setNote] = useState('');
	const [errors, setErrors] = useState({});

	const currentLocation = useLocation();
	const { data } = currentLocation.state || {};

	const validateFields = () => {
		let newErrors = {};
		if (!wallet) {
			newErrors.wallet = 'Пожалуйста, введите адрес получателя';
		} else if (/[а-яА-ЯёЁ]/.test(wallet)) {
			newErrors.wallet = 'Пожалуйста, введите актуальный адрес получателя';
		}
		if (!network) newErrors.network = 'Пожалуйста, выберите сеть отправки';
		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleWithdraw = async () => {
		if (!validateFields()) {
			return;
		}
		const paymentData = {
			...data,
			wallet: wallet,
			network: network,
			memo: memo ? memo : "-",
			note: note ? note : "-",
			email: store.user.email,
			userId: store.user.id
		}
		try{
			console.log(paymentData)
			await PaymentService.createPayment(paymentData);

		}catch (e){
			console.log(e)
		}
	};

	return (
		<div className="__exchange_details">
			<div className="container-top">
				{data ? (
					<div className="__details_wrapper">
						<div className="__details_form">
							<div className="__details">
								<h3>Детали заказа:</h3>

								<div className="__info">
									<span>Вы отправите:</span>
									<strong>{data.receivedNum} {data.receivedCurrency}</strong>
								</div>
								<div className="__info">
									<span>Вы получите:</span>
									<strong>{data.giveNum} {data.giveCurrency}</strong>
								</div>
								<div className="__info">
									<span>Курс:</span>
									<strong>{data.rate}</strong>
								</div>
							</div>


							<div className="__wallet">
								<h3>Адрес вывода:</h3>
								<CustomSelect
									options={[
										{value: 'ethereum', label: 'Ethereum'},
										{value: 'bsc', label: 'Binance Smart Chain (BSC)'},
										{value: 'tron', label: 'Tron'},
										{value: 'solana', label: 'Solana'}
									]}
									value={network}
									onChange={setNetwork}
									error={errors.network}
								/>
								<div className="__input_wrapper">
									<label>Адрес:</label>
									<input
										className={`input ${errors.fullName ? 'input-error' : ''}`}
										type="text"
										value={wallet}
										onChange={(e) => setWallet(e.target.value)}
									/>
									{errors.wallet && <p className="error">{errors.wallet}</p>}
								</div>
								<div className="__input_wrapper">
									<label>Memo (если требуется):</label>
									<input
										className='input'
										type="text"
										value={memo}
										onChange={(e) => setMemo(e.target.value)}
									/>
								</div>
								<div className="__input_wrapper">
									<label>Комментарий (по желанию):</label>
									<input
										className='input'
										type="text"
										value={note}
										onChange={(e) => setNote(e.target.value)}
									/>
								</div>


								<button onClick={handleWithdraw} className="__details_btn">
									Оплатить
								</button>
							</div>
						</div>
					</div>
				) : (
					<div>Нет доступных данных</div>
				)}
			</div>
		</div>
	);
}

export default ExchangeDetails;
