import React, { useState, useEffect } from 'react';
import './AdminExchange.scss';
import ExchangeService from '../../services/ExchangeService';

function AdminExchange() {
	const [toggleState, setToggleState] = useState(false);
	const [customRate, setCustomRate] = useState('');
	const [percent, setPercent] = useState('');
	const [startTime, setStartTime] = useState('');
	const [endTime, setEndTime] = useState('');
	const [rate, setRate] = useState('');

	useEffect(() => {
		const fetchRate = async () => {
			try {
				const response = await ExchangeService.getExchangeData();
				const data = response.data.settings;
				setToggleState(data.exchange_enabled);
				setCustomRate(data.custom_rate);
				setPercent(data.percent);
				setStartTime(data.start_time);
				setEndTime(data.end_time);
				setRate(response.data.rate);
			} catch (error) {
				console.error('Error fetching exchange rate:', error);
			}
		};
		fetchRate();
	}, []);

	const handleToggleChange = async () => {
		try {
			const newState = !toggleState;
			setToggleState(newState);
			await ExchangeService.toggleExchange(newState);
		} catch (error) {
			console.error('Error toggling exchange:', error);
		}
	};

	const handlePercentChange = (e) => {
		const value = e.target.value;
		if (/^\d*\.?\d*$/.test(value)) {
			setPercent(value);
		}
	};

	const handleCustomRateChange = (e) => {
		const value = e.target.value;
		if (/^\d*\.?\d*$/.test(value)) {
			setCustomRate(value);
		}
	};

	const handleTimeChange = (e, setter) => {
		const value = e.target.value.replace(/\D/g, '');
		let formattedValue = value;

		if (value.length > 4) {
			formattedValue = `${value.slice(0, 2)}:${value.slice(2, 4)}:${value.slice(4, 6)}`;
		} else if (value.length > 2) {
			formattedValue = `${value.slice(0, 2)}:${value.slice(2, 4)}`;
		} else {
			formattedValue = value;
		}

		if (/^\d{0,2}:?\d{0,2}:?\d{0,2}$/.test(formattedValue)) {
			setter(formattedValue);
		}
	};

	const handleParamsSubmit = async () => {
		try {
			await ExchangeService.setExchangeParams(parseFloat(customRate), parseFloat(percent));
		} catch (error) {
			console.error('Error setting percentage:', error);
		}
	};

	const handleTimeSubmit = async () => {
		try {
			await ExchangeService.setExchangeTime(startTime.padEnd(8, '0'), endTime.padEnd(8, '0'));
		} catch (error) {
			console.error('Error setting percentage:', error);
		}
	};

	return (
		<div className="admin-exchange">
			<h3 className="__title">Настройки обмена</h3>
			<div className="__controls">
				<div className="__header">
					<div className="__toggle">
						<label className="toggle-switch">
							<input
								type="checkbox"
								checked={toggleState}
								onChange={handleToggleChange}
							/>
							<span className="toggle-slider"></span>
						</label>
						<span className="toggle-label">{toggleState ? 'Обмен включен' : 'Обмен выключен'}</span>
					</div>
					<div className="__rate">
						<p className="__rate-title">
							1 USDT = <span>{rate}</span> RUB
						</p>
					</div>
				</div>
				<div className="__input-group">
					<div className="__input_wrapper">
						<label>Кастомный курс:</label>
						<input
							className="input"
							type="text"
							value={customRate}
							onChange={handleCustomRateChange}
						/>
					</div>
					<div className="__input_wrapper">
						<label>Процент:</label>
						<input
							className="input"
							type="text"
							value={percent}
							onChange={handlePercentChange}
						/>

					</div>
					<button
						onClick={handleParamsSubmit}
						className="__button margin"
					>
						Применить
					</button>
					<div className="__input_wrapper">
						<label>Начало обмена:</label>
						<input
							className="input"
							type="text"
							value={startTime}
							onChange={(e) => handleTimeChange(e, setStartTime)}
							placeholder="ЧЧ:ММ"
						/>

					</div>
					<div className="__input_wrapper">
						<label>Конец обмена:</label>
						<input
							className="input"
							type="text"
							value={endTime}
							onChange={(e) => handleTimeChange(e, setEndTime)}
							placeholder="ЧЧ:ММ"
						/>

					</div>
					<button
						onClick={handleTimeSubmit}
						className="__button"
					>
						Применить
					</button>

				</div>
			</div>
		</div>
	);
}

export default AdminExchange;
