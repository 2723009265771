import $api from "../http";

export default class ExchangeService {
	static async getExchangeRate() {
		return $api.get('/getExchangeRate');
	}

	static async getExchangeData() {
		return $api.get('/getExchangeData');
	}

	static async toggleExchange(state) {
		return $api.post('/toggleExchange', { state });
	}

	static async setExchangeParams(customRate, percent) {
		return $api.post('/setExchangeParams', {customRate, percent });
	}

	static async setExchangeTime(startTime, endTime) {
		return $api.post('/setExchangeTime', {startTime, endTime });
	}
}
