import React, { useState } from 'react';
import './CustomSelect.scss';

function CustomSelect({ options, value, onChange, error }) {
	const [isOpen, setIsOpen] = useState(false);
	const toggleDropdown = () => setIsOpen(!isOpen);

	const handleSelect = (option) => {
		onChange(option);
		setIsOpen(false);
	};

	return (
		<div className="custom-select-wrapper">
			<div
				className={`custom-select ${isOpen ? 'open' : ''} ${error ? 'select-error' : ''}`}
				onClick={toggleDropdown}
			>
				<div className="custom-select__trigger">
					<span>{value ? options.find(opt => opt.value === value).label : 'Выберите сеть'}</span>
					{/*<div className="arrow"></div>*/}
				</div>
				<div className={`custom-options ${isOpen ? 'visible' : ''}`}>
					{options.map((option) => (
						<div
							key={option.value}
							className={`custom-option ${option.value === value ? 'selected' : ''}`}
							onClick={() => handleSelect(option.value)}
						>
							{option.label}
						</div>
					))}
				</div>
			</div>
			{error && <p className="error">{error}</p>}
		</div>
	);
}

export default CustomSelect;
