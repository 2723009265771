import React, {useState} from 'react';
import "./Profile.scss";
import ProfileSettings from "../ProfileSettings/ProfileSettings";
import ProfileMenu from "../../components/ProfileMenu/ProfileMenu";
import ProfileTransactions from "../ProfileTransactions/ProfileTransactions";
import ProfileAccount from "../ProfileAccount/ProfileAccount";
import ProfileReferral from "../ProfileReferral/ProfileReferral";
import ProfileAdmin from "../ProfileAdmin/ProfileAdmin";
import ProfileSupport from "../ProfileSupport/ProfileSupport";


function Profile() {
	const [selectedButton, setSelectedButton] = useState('account');
	const [selectedItem, setSelectedItem ] = useState("account");

	const renderComponent = () => {
		switch (selectedButton) {
			case 'account':
				return <ProfileAccount/>;
			case 'transactions':
				return <ProfileTransactions/>;
			case 'referral':
				return <ProfileReferral setSelectedItem={setSelectedItem} setSelectedButton={setSelectedButton} />;
			case 'settings':
				return <ProfileSettings />;
			case 'support':
				// return <ProfileSupport />;
				return null;
			case 'admin':
				return <ProfileAdmin/>;
			default:
				return null;
		}
	};


	return (
		<div className="profile">
			<div className="container-top">
				<div className="__wrapper">
					<div className="__menu_container">
						<ProfileMenu setSelectedButton={setSelectedButton} selectedItem={selectedItem} setSelectedItem={setSelectedItem}/>
					</div>
					<div className="__content_container">{renderComponent()}</div>
				</div>
			</div>
		</div>
	);
}

export default Profile;
