import React, {useState, useEffect} from 'react';
import "./Exchanger.scss";
import tether from "../../img/tether.svg";
import rub from "../../img/rubIcon.svg";
import swap from "../../img/swapImg.svg";
import infoImg from "../../img/exchangeInfo.svg";
import ExchangeService from "../../services/ExchangeService";
import {useNavigate} from "react-router-dom";

function Exchanger() {
	const [selectedFirstInput, setSelectedFirstInput] = useState({
		transcript: "RUB",
		img: rub,
	});
	const [selectedSecondInput, setSelectedSecondInput] = useState({
		transcript: "USDT",
		img: tether,
	});
	const [firstInput, setFirstInput] = useState("");
	const [secondInput, setSecondInput] = useState("");
	const [isValidFirstInput, setIsValidFirstInput] = useState(false);
	const [isValidSecondInput, setIsValidSecondInput] = useState(false);
	const [rate, setRate] = useState("");
	const [status, setStatus] = useState("");
	const isButtonDisabled = status === "manual" || status === "time";

	const navigate = useNavigate();


	useEffect(() => {
		const fetchExchangeRate = async () => {
			try {
				const response = await ExchangeService.getExchangeRate();
				setRate(response.data.rate);
				setStatus(response.data.message);
			} catch (error) {
				console.error('Error fetching exchange rate:', error);
			}
		};

		fetchExchangeRate();
		const interval = setInterval(() => {
			fetchExchangeRate();
		}, 60000);

		return () => clearInterval(interval);
	}, []);




	const handleSwap = () => {
		// const tempFirstInput = { ...selectedFirstInput };
		// setSelectedFirstInput({ ...selectedSecondInput });
		// setSelectedSecondInput(tempFirstInput);
		// setFirstInput("");
		// setSecondInput("");
	}

	const handleInputChange = (event, isFirstInput) => {
		let inputValue = event.target.value;

		if(isFirstInput){
			setFirstInput(inputValue);
			setSecondInput((parseFloat(inputValue) / parseFloat(rate)).toFixed(2));
		}
		else{
			setSecondInput(inputValue)
			setFirstInput((parseFloat(inputValue) * parseFloat(rate)).toFixed(2));
		}
	}

	const handleSubmit = () => {
		firstInput === "" ? setIsValidFirstInput(true) : setIsValidFirstInput(false);
		secondInput === "" ? setIsValidSecondInput(true) : setIsValidSecondInput(false);
		const data = {
			receivedNum: firstInput,
			receivedCurrency: selectedFirstInput.transcript,
			giveNum: secondInput,
			giveCurrency: selectedSecondInput.transcript,
			rate: rate,
		}
		console.log(data)
		navigate('/details', { state: { data } });
	}

	return (
		<div className="exchanger">
			<h2>
				Купить {selectedFirstInput.transcript === "RUB" ? "USDT" : "RUB"}
				<div className="info-icon">
					<span className="tooltip-text">Время работы<br/> 08:00-20:00</span>
					<img src={infoImg} alt="img"/>
				</div>
			</h2>
			<div className="__input_wrapper">
				<div className="__item">
					<div className={isValidFirstInput ? "__content_wrapper invalid" : "__content_wrapper"}>
						<div className="__input">
							<div className="__text">
								<p>Вы отдадите: </p>
							</div>
							<input type="text"
							       value={/^[0-9.]*$/.test(firstInput) ? firstInput : ""}
							       onChange={(event) => handleInputChange(event, true)}
							       onFocus={(event) => event.target.select()}
							       placeholder="0.00"
							/>
						</div>
						<div className="__currency">
							<img src={selectedFirstInput.img} alt="img"/>
							<span>{selectedFirstInput.transcript}</span>
						</div>
					</div>
				</div>
				<div className="__swap">
					<img onClick={handleSwap} src={swap} alt="img"/>
				</div>
				<div className="__item">
					<div className={isValidSecondInput ? "__content_wrapper invalid" : "__content_wrapper"}>
						<div className="__input">
							<div className="__text">
								<p>Вы получите: </p>
							</div>
							<input type="text"
							       value={/^[0-9.]*$/.test(secondInput) ? secondInput : ""}
							       onChange={(event) => handleInputChange(event, false)}
							       onFocus={(event) => event.target.select()}
							       placeholder="0.00"
							/>
						</div>
						<div className="__currency">
							<img src={selectedSecondInput.img} alt="img"/>
							<span>{selectedSecondInput.transcript}</span>
						</div>
					</div>
				</div>
			</div>
			<div className="__purchase">
				<div className="__rate">
					<p>1 USDT = {rate} RUB</p>
				</div>
				<button
					onClick={handleSubmit}
					disabled={isButtonDisabled}
					className={isButtonDisabled ? "disabled-button" : ""}
				>
					{isButtonDisabled ? "Отключено" : "Купить"}
				</button>

			</div>

		</div>
);
}

export default Exchanger;