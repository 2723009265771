import React from 'react';
import "./AdminMenu.scss";

function AdminMenu({selectedItem, setSelectedItem, setSelectedButton}) {
	const handleButtonChange = (data) => {
		setSelectedButton(data);
		setSelectedItem(data);
	}
	return (
		<div className="admin_menu">
			<div className="admin_item">
				<button className={`__list_item ${selectedItem === 'exchange' ? 'selected' : ''}`} onClick={() => handleButtonChange("exchange")}>
					<span>Обмен</span>
				</button>
			</div>
			<div className="admin_item">
				<button className={`__list_item ${selectedItem === 'passport' ? 'selected' : ''}`} onClick={() => handleButtonChange("passport")}>
					<span>Паспорт</span>
				</button>
			</div>
			<div className="admin_item">
				<button className={`__list_item ${selectedItem === 'chat' ? 'selected' : ''}`} onClick={() => handleButtonChange("chat")}>
					<span>Чат</span>
				</button>
			</div>
		</div>
	);
}

export default AdminMenu;