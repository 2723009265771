import React, {useContext, useState} from 'react';
import "./ProfileReferral.scss";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import {CLIENT_URL} from "../../http";
import CopyNotification from "../../components/CopyNotification/CopyNotification";
import telegramImg from "../../img/refTelegram.svg";
import vkImg from "../../img/refVk.svg";
import whatsappImg from "../../img/refWhatsapp.svg";
import twitterImg from "../../img/refTwitter.svg";

function ProfileReferral({setSelectedItem, setSelectedButton}) {
	const {store} = useContext(Context);
	const [notification, setNotification] = useState({ visible: false, message: '' });

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text)
			.then(() => {
				setNotification({ visible: true, message: 'Текст скопирован в буфер обмена' });
			})
			.catch((err) => {
				console.error('Не удалось скопировать текст: ', err);
			});
	};
	const handleNotificationClose = () => {
		setNotification({ visible: false, message: '' });
	};
	return (
		<div className="referral">
			<div className="__header">
				<div className="__wrapper">
					<div className="__title">
						<h1>Пригласите друзей. Получайте скидки при обмене.</h1>
						<p>Получите до 40% скидок от суммы за каждую сделку</p>
					</div>
					{
						store.user.isVerified || store.user.isActivated ?
							<div className="__ref_info">
								<div className="__benefits">
									<div className="__receive">
										<p>Являетесь ли вы рефералом:</p>
										<h5>{store.user.referrer > 0 ? 'Да' : 'Нет'}</h5>
									</div>
									<div className="__receive">
										<p>Количество ваших рефералов:</p>
										<h5>{store.user.referrals || 0}</h5>
									</div>
								</div>
								<div className="__ref_id">
									<h3>ID реферала</h3>
									<div className="__item">
										{store.user.id}
									</div>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
									     cursor="pointer"
									     className="__copy" onClick={() => copyToClipboard(store.user.id)}>
										<path fillRule="evenodd" clipRule="evenodd"
										      d="M9 3h11v13h-3V6H9V3zM4 8v13h11V8.02L4 8z" fill="currentColor"></path>
									</svg>
								</div>
								<div className="__ref_link">
									<h3>Реферальная ссылка</h3>
									<div className="__item">
										https://dexpay.ru....={store.user.id}
									</div>
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
									     cursor="pointer"
									     className="__copy"
									     onClick={() => copyToClipboard(`${CLIENT_URL}/registration?ref=${store.user.id}`)}>
										<path fillRule="evenodd" clipRule="evenodd"
										      d="M9 3h11v13h-3V6H9V3zM4 8v13h11V8.02L4 8z" fill="currentColor"></path>
									</svg>
								</div>
								<div className="__share_links">
									<a href={`https://t.me/share/url?url=https://dexpay.ru/registration?ref=${store.user.id}&text=Нажмите на ссылку чтобы создать аккаунт`}
									   target="_blank" rel="noopener noreferrer">
										<img src={telegramImg} alt="img"/>
									</a>
									<a href={`https://api.whatsapp.com/send?text=Нажмите на ссылку чтобы создать аккаунт: ${CLIENT_URL}/registration?ref=${store.user.id}`}
									   target="_blank" rel="noopener noreferrer">
										<img src={whatsappImg} alt="img"/>
									</a>
									<a href={`https://vk.com/share.php?url=${CLIENT_URL}/registration?ref=${store.user.id}&title=Регистрация&description=Нажмите на ссылку чтобы создать аккаунт`}
									   target="_blank" rel="noopener noreferrer">
										<img src={vkImg} alt="img"/>
									</a>
									<a href={`https://x.com/intent/tweet?url=${CLIENT_URL}/registration?ref=${store.user.id}&text=Нажмите на ссылку чтобы создать аккаунт`}
									   target="_blank" rel="noopener noreferrer">
										<img src={twitterImg} alt="img"/>
									</a>
								</div>
							</div> :
							<div className="__ref_info __verification_prompt">
								<h3>Для получения доступа к реферальной программе, необходимо пройти верификацию уровня "Верифицированный"</h3>
								<button className="__verify_button" onClick={() => {
									setSelectedButton('account')
									setSelectedItem('account')
								}}>
									Пройти верификацию
								</button>
							</div>
					}

				</div>
			</div>
			<div className="__rules">
				<h1>Правила реферальной программы</h1>
				<p><span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad delectus distinctio eos est fugiat iusto minus natus repellendus voluptas. Consectetur explicabo incidunt libero molestias necessitatibus neque non obcaecati pariatur suscipit.</span><span>A ab ad alias asperiores assumenda aut, consectetur debitis delectus earum fugit labore libero, mollitia placeat quam quo quos recusandae tempora tenetur veniam voluptatem voluptates voluptatibus voluptatum! Assumenda, ipsum tempora!</span><span>Amet, aperiam deserunt eligendi iusto nisi suscipit. Aliquam autem commodi ducimus explicabo facilis itaque nisi odit reprehenderit rerum vel. Fugit ipsam iste iusto molestias quam quod vel voluptate! Adipisci, nisi!</span><span>Esse et eveniet hic illo ipsam itaque laborum nobis praesentium ratione, rem saepe soluta totam voluptas? Cumque, dignissimos, eum facere impedit magnam nisi non nostrum officia quas quasi quidem repellat?</span><span>Architecto culpa doloremque ex explicabo fugiat laborum, officiis perspiciatis quaerat repudiandae soluta sunt veniam. Aliquid debitis distinctio eum exercitationem facere fugiat ipsa iste, minus, nemo obcaecati odio reprehenderit sapiente tenetur?</span>
				</p>
			</div>
			<CopyNotification message={notification.message} visible={notification.visible}
			                  onClose={handleNotificationClose}/>
		</div>
	);
}

export default observer(ProfileReferral);