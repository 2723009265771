import React, {useContext, useEffect, useState} from 'react';
import "./ProfileAccount.scss";
import accountImg from "../../img/accountUserImg.svg";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";
import mailImg from "../../img/profileLimitMail.svg";
import periodImg from "../../img/profileLimitTime.svg";
import limitImg from "../../img/profileLimit.svg";
import docsImg from "../../img/profileID.svg"
import ConfirmEmail from "../../components/ConfirmEmail/ConfirmEmail";
import PassportModal from "../../components/PassportModal/PassportModal";
import PersonalDataService from "../../services/PersonalDataService";

function ProfileAccount() {
	const [modalActive, setModalActive] = useState(false);
	const [dropdownIsActive, setDropdownIsActive] = useState(false);
	const [showConfirmEmail, setShowConfirmEmail] = useState(false);
	const [isPassportSent, setIsPassportSent] = useState(false);
	const {store} = useContext(Context);

	const handleConfirmEmail = async () => {
		const email = store.user.email;
		try {
			await store.confirmEmail(email);
			setShowConfirmEmail(true);
		} catch (error) {
			console.error('Ошибка при регистрации:', error);
		}
	}

	const handleOpenModal = () => {
		setModalActive(true);
	}

	useEffect(() => {
		const fetchPassportStatus = async () => {
			try {
				const response = await PersonalDataService.checkValidation(store.user.id);
				response.data.status === 'process' ? store.setIsPassportProcess(true): store.setIsPassportProcess(false);
			} catch (error) {
				console.error('Ошибка при проверке паспорта:', error);
			}
		};

		fetchPassportStatus();
	}, [isPassportSent]);
	return (
		<div className="account">
			<div className="__header">
				<div className="__title">
					<img src={accountImg} alt="img"/>
					<p>{store.user.email}</p>
				</div>
				<div className={`__info ${dropdownIsActive ? "active" : ""}`}>
					<div className="__item">
						<p>User ID</p>
						<span>{store.user.id}</span>
					</div>
					<div className="__item">
						<p>Верификация</p>
						<span>{store.user.isActivated ? store.user.isVerified ? "Верифицированный пользователь" : "Обычный пользователь" : "Нет"}</span>
					</div>
					<div className="__item">
						<p>Рефералы</p>
						<span>{store.user.referrals || "Нет"}</span>
					</div>
					<div className="__item display_none">
						<p>Выход</p>
						<svg onClick={() => store.logout()} className="logout_img" width="64px" height="64px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
							<g id="SVGRepo_iconCarrier"> <path d="M21 12L13 12" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
								<path d="M18 15L20.913 12.087V12.087C20.961 12.039 20.961 11.961 20.913 11.913V11.913L18 9" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
								<path d="M16 5V4.5V4.5C16 3.67157 15.3284 3 14.5 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H14.5C15.3284 21 16 20.3284 16 19.5V19.5V19" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
							</g>
						</svg>
					</div>
				</div>
				<div className="__dropdown" onClick={() => setDropdownIsActive(!dropdownIsActive)}>
					<svg fill="iconNormal" size="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
					     className={`bn-svg ${dropdownIsActive ? "active" : ""}`}>
						<path fillRule="evenodd" clipRule="evenodd"
						      d="M12.11 12.178L16 8.287l1.768 1.768-5.657 5.657-1.768-1.768-3.889-3.889 1.768-1.768 3.889 3.89z"
						      fill="#fff">
						</path>
					</svg>
				</div>
			</div>
			<div className="__verification">
				<div className="__table">
					{
						showConfirmEmail ? <ConfirmEmail/> :
							<div className="__regular">
								<h3>Обычный пользователь</h3>
								<ul className="__main_list">
									<div className="__info">
										<img src={mailImg} alt="img"/>
										<li>Подтверждение почты</li>
									</div>
								</ul>
								<div className="__limits">
									<div className="__title">
										<img src={limitImg} alt="img"/>
										<h5>Лимиты в рублях на ввод средств</h5>
									</div>
									<p>50K - 100K рублей <span>Ежедневно</span></p>
								</div>
								{
									store.user.isActivated ?
										<div className="__btn_active">Завершено</div> :
										<button onClick={handleConfirmEmail}>Подтвердить</button>
								}
							</div>
					}
					<div className="__verified">
						<h3>Верифицированный</h3>
						<ul className="__main_list">
							<div className="__info">
								<img src={docsImg} alt="img"/>
								<li>Необходимо предоставить:</li>
							</div>
							<ul>
								<li>ИНН</li>
								<li>Паспорт</li>
							</ul>
							<div className="__info">
								<img src={periodImg} alt="img"/>
								<li>Срок рассмотрения: <span>7 дней</span></li>
							</div>
						</ul>

						<div className="__limits">
							<div className="__title">
								<img src={limitImg} alt="img"/>
								<h5>Лимиты в рублях на ввод средств</h5>
							</div>
							<p>100K - 1M рублей <span>Ежедневно</span></p>
						</div>
						{
							!store.user.isActivated ?
								<button className="__btn_process" disabled>Недоступно</button> :
							store.user.isVerified ?
								<button className="__btn_active">Завершено</button> :
							store.isPassportProcess ?
								<button className="__btn_process">В процессе</button> :
								<button onClick={handleOpenModal}>Начать</button>
						}
					</div>
				</div>
			</div>
			{
				modalActive && <PassportModal setModalActive={setModalActive} setIsPassportSent={setIsPassportSent}/>
			}
		</div>
	);
}

export default observer(ProfileAccount);